import React, { useState } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet, ScrollView } from 'react-native';
import { colors, fonts, spacing, commonStyles } from '../theme';
import { Logger } from './../logger';
import { useAuth } from '../AuthContext';
import { selectCustomer } from '../src/api/customerAPI';
import { FlatList } from 'react-native-gesture-handler';



const ProfileScreen = () => {
  const [activeTab, setActiveTab] = useState('account');
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const { user, token, loading } = useAuth();

const handleSelectCustomer = async (customerid) => {
  try {
    const response = await selectCustomer(customerid, token);
    if (response.ok){
      Logger.log('Customer Selected', response.data.firstName)
      setSelectedCustomer(response.data)
    }else {
      showMessage('Error Selecting Customer')
    }
  }catch (error) {
    showMessage('Error Selecting customer' + error.message, true)
  }
}


  const renderTabContent = () => {
    switch (activeTab) {
      case 'account':
        return <AccountTab />;
      case 'address':
        return <AddressTab />;
      case 'settings':
        return <SettingsTab />;
      default:
        return null;
    }
  };

  return (
    <ScrollView style={styles.container}>
      <View style={styles.profileHeader}>
        <Image
          source={{ uri: 'https://example.com/profile-picture.jpg' }}
          style={styles.profilePicture}
        />
        <Text style={styles.profileName}>{user.firstName} {user.lastName}</Text>
      </View>
      <View style={styles.tabContainer}>
        <TouchableOpacity
          style={[styles.tabButton, activeTab === 'account' && styles.activeTab]}
          onPress={() => {
            setActiveTab('account')
            handleSelectCustomer(user.id)}}
        >
          <Text style={styles.tabText}>Account</Text>
          <FlatList style={styles.listContainer}>
            <View style={styles.listItem}></View>
          </FlatList>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.tabButton, activeTab === 'address' && styles.activeTab]}
          onPress={() => setActiveTab('address')}
        >
          <Text style={styles.tabText}>Address</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.tabButton, activeTab === 'settings' && styles.activeTab]}
          onPress={() => setActiveTab('settings')}
        >
          <Text style={styles.tabText}>Settings</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.tabContent}>
        {renderTabContent()}
      </View>
    </ScrollView>
  );
};

const AccountTab = () => (
  <View>
    <Text style={styles.tabTitle}>Account Information</Text>
    {/* Add account information fields here */}
  </View>
);

const AddressTab = () => (
  <View>
    <Text style={styles.tabTitle}>Address Information</Text>
    {/* Add address information fields here */}
  </View>
);

const SettingsTab = () => (
  <View>
    <Text style={styles.tabTitle}>Settings</Text>
    {/* Add settings options here */}
  </View>
);

const styles = StyleSheet.create({
  ...commonStyles,
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  profileHeader: {
    alignItems: 'center',
    padding: 20,
  },
  profilePicture: {
    width: 100,
    height: 100,
    borderRadius: 50,
  },
  profileName: {
    fontSize: 24,
    fontWeight: 'bold',
    marginTop: 10,
  },
  tabContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  tabButton: {
    paddingVertical: 10,
    flex: 1,
    alignItems: 'center',
  },
  activeTab: {
    borderBottomWidth: 2,
    borderBottomColor: '#007AFF',
  },
  tabText: {
    fontSize: 16,
  },
  tabContent: {
    padding: 20,
  },
  tabTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
});

export default ProfileScreen;