import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { colors, fonts, spacing, commonStyles } from '../theme';
import { Logger } from './../logger';
import { Dimensions } from 'react-native';


export default function AccountScreen() {
  return (
    <View style={styles.container}>
      <Text>Account Screen</Text>
    </View>
  );
}

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  ...commonStyles,
  container: {
    width: .5 * width,
  }
});