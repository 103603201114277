// theme.js
import { Dimensions } from 'react-native';

const { width } = Dimensions.get('window');

export const colors = {
  primary: '#007AFF',
  background: '#f5f5f5',
  button: '#0DB7ED',
  white: '#ffffff',
  text: '#000000',
  border: '#ddd',
  error: '#FF3B30',
  success: '#34C759',
  shadow: '#000',
};

export const fonts = {
  regular: 14,
  medium: 16,
  large: 18,
  extraLarge: 24,
};

export const spacing = {
  small: 5,
  medium: 10,
  large: 15,
  extraLarge: 20,
};

export const commonStyles = {
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.background,
    marginBottom: 100,
  },
  
  formContainer: {
    width: width * 0.8,
    maxWidth: 400,
    alignItems: 'center',
    backgroundColor: colors.white,
    padding: spacing.extraLarge,
    borderRadius: 8,
    shadowColor: colors.shadow,
    shadowoffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
},

  input: {
    height: 50,
    width: '100%',
    borderColor: colors.border,
    borderWidth: 1,
    marginBottom: spacing.large,
    padding: spacing.medium,
    borderRadius: 5,
    backgroundColor: colors.white,
  },
  button: {
    backgroundColor: colors.button,
    padding: spacing.large,
    borderRadius: 5,
    width: '100%',
    alignItems: 'center',
  },

  buttonText: {
    color: colors.white,
    fontSize: fonts.medium,
    fontWeight: 'bold',
  },

  title: {
    fontSize: fonts.extraLarge,
    fontWeight: 'bold',
    marginBottom: spacing.large,
    textAlign: 'center',
  },
};

export const logoStyles = {
    logoContainer: {
        marginBottom: 40,
        width: width * 0.5,
        height: 250,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.background,
    },
    logo: {
        width: '100%',
        height: '100%',
    },
    forgotPassword: {
        color: colors.button,
        textAlign: 'center',
        marginTop: 15,
      },
    footer: {
        flexDirection: 'row',
        marginTop: 20,
    },
    footerText: {
        color: '#666',
    },
    signUpText: {
        color: '#0DB7ED',
        fontWeight: 'bold',
    },
};
