import React, { createContext, useState, useContext, useEffect } from 'react';
import { Logger } from './logger';
import { API_URL } from '@config/config'

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser_) : null;
    });
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    useEffect(() => {
        checkToken();
    }, []);

    const checkToken = async () => {
        try {
            if (isInitialLoad) {
                setIsInitialLoad(false);
                setLoading(false);
                return;
            }

            const storedToken = localStorage.getItem('Token');
            const storedUser = localStorage.getItem('user');

            if (storedToken) {
                setToken(storedToken);
                if (storedUser) {
                    setUser(JSON.parse(storedUser));
                }

                const response = await fetch(`${API_URL}/auth`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${storedToken}`,
                    },
                });
                if (response.ok) {
                    const userData = await response.json();
                    setUser(userData);
                    localStorage.setItem('user', JSON.stringify(userData))
                } else {
                    await logout();
                }
            }
        } catch (error) {
            Logger.error('Error checking token:', error);
            await logout();
        } finally {
            setLoading(false);
        }
    };

    const register = async (firstName, lastName, email, password) => {
        try {
            const response = await fetch(`${API_URL}/auth/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ firstName, lastName, email, password })
            });
            if (response.ok) {
                Logger.dev('Registration successful');
                const userData = await response.json();
                Logger.dev('User data:', userData)

                const loginSuccess = await login(email, password)
                if (loginSuccess) {
                    Logger.dev('Auto-login after registration successful');
                    return true;
                } else {
                    Logger.dev('Auto-login after registration failed');
                    return false;
                }
            } else {
                const errorData = await response.json();
                console.error('Registration failed:', errorData);
                return false;
            }
        } catch (error) {
            console.error('Error registering:', error);
            return false;
        }
    }

    const login = async (email, password) => {
        Logger.dev('The API_URL is:', API_URL);
        Logger.dev('Attempting to login with URL:', `${API_URL}/auth/login`);
        try {
            const response = await fetch(`${API_URL}/auth/login`, {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });
            if (response.ok) {
                Logger.dev('Login successful')
                const data = await response.json();
                localStorage.setItem('token', data.token);
                localStorage.setItem('user', JSON.stringify(data.user));
                setToken(data.token);
                Logger.dev('User state updated:', data.user);
                return true;
                
            }
            return false;
        } catch (error) {
            console.error('Error logging in:', error);
            return false;
        }
    };

    const logout = async () => {
        try {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            setUser(null);
            setToken(null);
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    return (
        <AuthContext.Provider value={{ user, token, login, logout, register, loading, setUser, setToken }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}
