import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { Platform } from 'react-native';
import { Logger } from './logger';
import { AUTH0_MICROSERVICE_URL, AUTH0_CALLBACK_URL, AUTH0_DOMAIN, AUTH0_CLIENT_ID } from '@config/config';

const Auth0Context = createContext(null);

const auth0Config = {
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
};

const getRedirectUri = () => {
  if (Platform.OS === 'web') {
    return AUTH0_CALLBACK_URL;
  }
  return null;
};

const validateTokensWithBackend = async (idToken) => {
  try {
    const response = await fetch(`${AUTH0_MICROSERVICE_URL}/auth0/validate-user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ idToken })
    });

    if (response.ok) {
      const data = await response.json();
      return {
        token: data.token,
        user: {
          id: data.user.id,
          email: data.user.email,
          firstName: data.user.firstName,
          lastName: data.user.lastName,
          number: data.user.number
        }
      };
    }
    return null;
  } catch (error) {
    Logger.error('Error validating with Auth0 service:', error);
    return null;
  }
};

export const Auth0Provider = ({ children }) => {
  const [auth0Loading, setAuth0Loading] = useState(true);
  const auth = useAuth();
  const { setToken, setUser } = auth;

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        if (Platform.OS === 'web') {
          const hasAuthParams = window.location.hash && 
            (window.location.hash.includes('access_token') || 
             window.location.hash.includes('error'));

          if (!hasAuthParams) {
            setAuth0Loading(false);
          }
        }
      } catch (error) {
        Logger.error('Error initializing Auth0:', error);
        setAuth0Loading(false);
      }
    };

    initializeAuth();
  }, []);

  useEffect(() => {
    if (Platform.OS === 'web') {
      const handleAuthCallback = async () => {
        try {
          const params = new URLSearchParams(window.location.hash.substring(1));
          const error = params.get('error');
          const idToken = params.get('id_token');
          
          if (error) {
            Logger.error('Authentication error:', error);
            return;
          }

          if (idToken) {
            setAuth0Loading(true);
            const backendData = await validateTokensWithBackend(idToken);
            
            if (backendData && backendData.token) {
              setToken(backendData.token);
              setUser(backendData.user);
              Logger.dev('Backend validation successful:', backendData);
            } else {
              Logger.error('Backend validation failed or no token received');
            }

            // Clear the URL hash
            window.history.replaceState({}, document.title, window.location.pathname);
          }
        } catch (error) {
          Logger.error('Error handling auth callback:', error);
        } finally {
          setAuth0Loading(false);
        }
      };

      handleAuthCallback();
    }
  }, []);

  const auth0Login = async () => {
    try {
      setAuth0Loading(true);
      
      if (Platform.OS === 'web') {
        const redirectUri = getRedirectUri();
        const state = Math.random().toString(36).substring(2);
        const nonce = Math.random().toString(36).substring(2);

        sessionStorage.setItem('auth0_state', state);
        sessionStorage.setItem('auth0_nonce', nonce);

        const authUrl = new URL(`https://${auth0Config.domain}/authorize`);
        const params = {
          response_type: 'token id_token',
          client_id: auth0Config.clientId,
          redirect_uri: redirectUri,
          scope: 'openid profile email',
          state: state,
          nonce: nonce,
        };

        authUrl.search = new URLSearchParams(params).toString();
        Logger.dev('Redirecting to:', authUrl.toString());
        window.location.href = authUrl.toString();
        return true;
      }
      return false;
    } catch (error) {
      Logger.error('Auth0 login error:', error);
      return false;
    }
  };

  const auth0Logout = async () => {
    try {
      if (Platform.OS === 'web') {
        const returnTo = encodeURIComponent(getRedirectUri());
        const logoutUrl = `https://${auth0Config.domain}/v2/logout?client_id=${auth0Config.clientId}&returnTo=${returnTo}`;
        window.location.href = logoutUrl;
      }
    } catch (error) {
      Logger.error('Auth0 logout error:', error);
    }
  };

  return (
    <Auth0Context.Provider value={{
      auth0Loading,
      auth0Login,
      auth0Logout
    }}>
      {children}
    </Auth0Context.Provider>
  );
};

export const useAuth0 = () => {
  const context = useContext(Auth0Context);
  if (!context) {
    throw new Error('useAuth0 must be used within an Auth0Provider');
  }
  return context;
};

export default Auth0Context; 