const config = {
  development: {
    API_URL: 'http://localhost:3000',
    AUTH0_MICROSERVICE_URL: 'http://localhost:3001',
    AUTH0_CALLBACK_URL: 'http://localhost:19006',
    AUTH0_DOMAIN: 'dev-t7r8f64utb74md67.us.auth0.com',
    AUTH0_CLIENT_ID: 'olUdIeWfXnLrYwLsCitkl2M0qHsjevJb',
    AUTH0_AUDIENCE: 'https://dev-t7r8f64utb74md67.us.auth0.com/api/v2/',
    LOG_LEVEL: 'debug'
  },
  production: {
    API_URL: process.env.REACT_NATIVE_API_URL || 'https://billingapp.ryan.k736.net/api',
    AUTH0_MICROSERVICE_URL: 'https://default-blackbird-ryans-organization-7b79f-0.blackbird-relay.a8r.io/auth0-microservice1',
    AUTH0_CALLBACK_URL: 'https://billingapp.ryan.k736.net',
    AUTH0_DOMAIN: 'dev-t7r8f64utb74md67.us.auth0.com',
    AUTH0_CLIENT_ID: 'olUdIeWfXnLrYwLsCitkl2M0qHsjevJb',
    AUTH0_AUDIENCE: 'https://dev-t7r8f64utb74md67.us.auth0.com/api/v2/',
    LOG_LEVEL: 'error'
  }
};

const currentConfig = __DEV__ ? config.development : config.production;

export const {
  API_URL,
  AUTH0_MICROSERVICE_URL,
  AUTH0_CALLBACK_URL,
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE,
  LOG_LEVEL
} = currentConfig;