import { Logger } from '../../logger';
import { API_URL } from '@config/config';

export const createUser = async (user, token) => {
    try {
      const response = await fetch(`${API_URL}/account`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      }, [token]);
      
      if (response.ok){
        const newUser = await response.json();
        Logger.dev(newUser);
        return newUser;      
      }else {
        return error
      }
      
    } catch (error) {
      console.error('Error creating user:', error);
    }
  }

  export const createTransaction = async(trans, token) => {
    try {
      Logger.dev(`Sending POST request to ${API_URL}/trans`);
      const response = await fetch(`${API_URL}/trans`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(trans),
      }, [token]);
      if (response.ok){
        const newTrans = await response.json();
        Logger.dev('New transaction created:', newTrans);
        return newTrans;
      }else{
        return error
      }
    } catch (error) {
      console.error('Error creating Transaction:', error);
      return null;
    }
  }

  export const fetchCustomers = async (token) => {
    try {
      const response = await fetch(`${API_URL}/accounts`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        },  [token]);
      if (response.ok){
        return await response.json();

      }else{
        console.error('Error Fetching customers', response.status)
      }
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  export const addBillingAddress = async (address, token) => {
    try {
      const response = await fetch(`${API_URL}/billing-address`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(address),
      });
      if (response.ok) {
        return await response.json();
      } else {
        throw new Error('Failed to add billing address');
      }
    } catch (error) {
      Logger.error('Error adding billing address:', error);
      throw error;
    }
  };

  export const selectCustomer = async (customerId, token) => {
    Logger.dev('Customer selected from backend:', customerId)
    if(customerId){
        try{
            const response = await fetch(`${API_URL}/account/${customerId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                const errorData = await response.text();
                throw new Error(`http error! status: ${response.status}, message: ${errorData}`)
            }

            return await response.json();
        }catch (error) {
            showMessage('customerId not found')
        } 
    }else {
    showMessage('customerId not provided')
    }
};