import React, { useState } from 'react';
import { View, TextInput, StyleSheet, Text, Pressable, Image, Dimensions, Alert } from 'react-native';
import { useAuth } from '../AuthContext';
import { useAuth0 } from '../Auth0Context';
import { colors, fonts, spacing, commonStyles, logoStyles } from '../theme';
import { Logger } from '../logger';

const LoginScreen = ({ navigation }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const { auth0Login } = useAuth0();

  const handleLogin = async () => {
    const success = await login(email, password);
    if (!success) {
      Alert.alert('Login Failed', 'Please check your credentials and try again.');
    }
  };

  const handleAuth0Login = async () => {
    try {
      await auth0Login();
    } catch (error) {
      Logger.error('Auth0 login error:', error);
      Alert.alert('Login Failed', 'Unable to login with Auth0');
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.logoContainer}>
        <Image
          source={require('../assets/Initech_logo.png')}
          style={styles.logo}
          resizeMode="contain"
        />
      </View>
      <View style={styles.formContainer}>
        <Text style={styles.title}>Sign In</Text>
        <TextInput
          style={styles.input}
          placeholder="Username or email address"
          value={email}
          onChangeText={setEmail}
          inputMode="email"
          autoCapitalize="none"
        />
        <TextInput
          style={styles.input}
          placeholder="Password"
          value={password}
          onChangeText={setPassword}
          secureTextEntry
        />
        <Pressable style={styles.button} onPress={handleLogin}>
          <Text style={styles.buttonText}>Sign In</Text>
        </Pressable>
        <View style={styles.dividerContainer}>
          <View style={styles.divider} />
          <Text style={styles.dividerText}>or</Text>
          <View style={styles.divider} />
        </View>
        <Pressable 
          style={({ pressed }) => [
            styles.button,
            styles.auth0Button,
            pressed && styles.auth0ButtonPressed
          ]}
          onPress={handleAuth0Login}
        >
          <Text style={styles.buttonText}>
            Continue with Auth0
          </Text>
        </Pressable>
        <Pressable onPress={() => {}}>
          <Text style={styles.forgotPassword}>Forgot password?</Text>
        </Pressable>
      </View>
      <View style={styles.footer}>
        <Text style={styles.footerText}>New to our demo platform? </Text>
        <Pressable onPress={() => navigation.navigate('Register')}>
          <Text style={styles.signUpText}>Sign Up</Text>
        </Pressable>
      </View>
    </View>
  );
};

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  ...commonStyles,
  ...logoStyles,
  auth0Button: {
    backgroundColor: '#635DFF',
    marginTop: spacing.medium,
  },
  auth0ButtonPressed: {
    opacity: 0.8,
  },
  dividerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: spacing.medium,
  },
  divider: {
    flex: 1,
    height: 1,
    backgroundColor: colors.border,
  },
  dividerText: {
    marginHorizontal: spacing.medium,
    fontSize: fonts.body,
    color: colors.text,
  },
});

export default LoginScreen;
