import React, { useState } from 'react';
import { View, TextInput, Button, StyleSheet, Alert, Text, TouchableOpacity, Dimensions, Pressable, Image } from 'react-native';
import { useAuth } from '../AuthContext';
import { colors, fonts, spacing, commonStyles, logoStyles } from '../theme';
import { Logger } from './../logger';



const RegisterScreen = ({ navigation }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const { register } = useAuth();

  Logger.dev('commonStyles:', commonStyles);
  Logger.dev('logoStyles:', logoStyles);
  
  const handleRegister = async () => {
    const success = await register(firstName, lastName, email, password);
    Logger.dev('Registration success:', success);
    if (!success) {
      Alert.alert('Registration Failed', 'Please enter your information in again.');
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.logoContainer}>
        <Image
          source={require('../assets/Ambassador-Logo-Vertical.png')}
          style={styles.logo}
          resizeMode="contain"
        />
      </View>
      <View style={styles.formContainer}>
        <Text style={styles.title}>Register</Text>
        <TextInput
          style={styles.input}
          placeholder="First Name"
          value={firstName}
          onChangeText={setfirstName}
          keyboardType="default"
          autoCapitalize="words"
        />
        <TextInput
            style={styles.input}
            placeholder="Last Name"
            value={lastName}
            onChangeText={setlastName}
            keyboardType="default"
            autoCapitalize="words"
        />
        <TextInput
          style={styles.input}
          placeholder="Email"
          value={email}
          onChangeText={setEmail}
          keyboardType="email-address"
          autoCapitalize="none"
        />
        <TextInput
          style={styles.input}
          placeholder="Password"
          value={password}
          onChangeText={setPassword}
          secureTextEntry
        />
        <TouchableOpacity style={styles.button} onPress={handleRegister}>
          <Text style={styles.buttonText}>Register</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.footer}>
        <Text style={styles.footerText}>Already have an account? </Text>
        <Pressable onPress={() => navigation.navigate('Login')}>
          <Text style={styles.signUpText}>Login</Text>
        </Pressable>
      </View>
    </View>
  );
};

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  ...commonStyles,
  ...logoStyles,
});

export default RegisterScreen;