import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useAuth } from '../AuthContext';
import { colors, fonts, spacing, commonStyles } from '../theme';
import { Logger } from './../logger';
import { Dimensions } from 'react-native';
import { API_URL } from '@config/config';


const ListItem = ({ account }) => (
  <View style={styles.listItem}>
    <View style={styles.accountInfo}>
      <Text style={styles.name}>{`${account.firstName} ${account.lastName}`}</Text>
      <Text style={styles.accountNumber}>Account #: {account.number}</Text>
    </View>
    <Text style={[styles.balance, account.balance < 0 ? styles.negativeBalance : styles.positiveBalance]}>
      ${account.balance.toFixed(2)}
    </Text>
  </View>
);

const AccountList = () => {
  const { user, token, login, loutout, loading } = useAuth();
  const [accounts, setAccounts] = useState([]);
  const [error, setError] = useState(null);
  
  useFocusEffect(
    React.useCallback(() => {
      getAccounts();
    }, [token])
  );

  async function getAccounts() {
    try {
      Logger.dev('Retrieved token:', token);
      
      if (!token) {
        throw new Error('No token found');
      }
      Logger.dev('Token:', token);

      const response = await fetch(`${API_URL}/accounts`, {
        method: 'GET',
        headers: {
          'Authorization' : `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }, [token]);

      Logger.dev('Response:', response.status)

      if (!response.ok) {
        const errorText = await response.text();
        Logger.error('Error Response:', errorText);
        throw new Error(`HTTP Error! status: ${response.status}, message: ${errorText}` )
      }

      const text = await response.text();
      Logger.dev('Response text:', text);

      const data = JSON.parse(text);
      setAccounts(data);
      setError(null);
    } catch (error) {
      console.error('Error fetching accounts:', error);
      setError(error.message);
    }
  }
  
  if(error) {
    return <Text style={styles.errorText}>{error}</Text>
  }

  return (
    <FlatList
      style={styles.listContainer}
      data={accounts}
      renderItem={({ item }) => <ListItem account={item} />}
      keyExtractor={(item) => item.id.toString()}
      ItemSeparatorComponent={() => <View style={styles.separator} />}
    />
  );
};

export default function BalanceScreen() {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Account Balances</Text>
      <View style={styles.titleSeparator} />
      <AccountList />
    </View>
  );
}

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  ...commonStyles,
  listContainer: {
    maxWidth: 800,
    backgroundColor: '#f9f9f9',
    width: width * 0.8,
    backgroundColor: colors.white,
    padding: spacing.extraLarge,
    borderRadius: 8,
    shadowColor: colors.shadow,
    shadowoffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  listItem: {
    
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 15,
    
  },
  accountInfo: {
    flex: 1,
  },
  name: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  accountId: {
    fontSize: 12,
    color: '#666',
  },
  balance: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  positiveBalance: {
    color: 'green',
  },
  negativeBalance: {
    color: 'red',
  },
  accountNumber: {
    fontSize: 12,
    color: '#666',
  },
});