import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import { colors, fonts, spacing, commonStyles } from '../theme';
import { Logger } from './../logger';


const LoadingScreen = () => (
  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
    <ActivityIndicator size="large" />
  </View>
);

export default LoadingScreen;