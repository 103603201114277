// logger.js

const LOG_LEVELS = {
    DEV: 0,
    DEBUG: 1,
    INFO: 2,
    WARN: 3,
    ERROR: 4,
  };
  
  const CURRENT_LOG_LEVEL = __DEV__ ? LOG_LEVELS.DEV : LOG_LEVELS.WARN;
  
  const log = (level, message, ...args) => {
    if (level >= CURRENT_LOG_LEVEL) {
      const timestamp = new Date().toISOString();
      const prefix = `[${timestamp}] ${LOG_LEVELS[level]}:`;
      
      switch (level) {
        case LOG_LEVELS.DEV:
            console.log(prefix, message, ...args);
            break;
        case LOG_LEVELS.DEBUG:
            console.log(prefix, message, ...args);
            break;
        case LOG_LEVELS.INFO:
            console.info(prefix, message, ...args);
            break;
        case LOG_LEVELS.WARN:
            console.warn(prefix, message, ...args);
            break;
        case LOG_LEVELS.ERROR:
            console.error(prefix, message, ...args);
            break;
      }
    }
  };
  
  export const Logger = {
    dev: (message, ...args) => log(LOG_LEVELS.DEV, message, ...args),
    debug: (message, ...args) => log(LOG_LEVELS.DEBUG, message, ...args),
    info: (message, ...args) => log(LOG_LEVELS.INFO, message, ...args),
    warn: (message, ...args) => log(LOG_LEVELS.WARN, message, ...args),
    error: (message, ...args) => log(LOG_LEVELS.ERROR, message, ...args),
  };